import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DevicesState, Device } from '../../../../models/Device.model';

const getInitialState = (): DevicesState => ({
  devices: [],
});

const slice = createSlice({
  name: 'devices',
  initialState: getInitialState(),
  reducers: {
    setDevices: (state, action: PayloadAction<Device[]>) => {
      state.devices = action.payload;
    },
    deleteDevice: (state, action: PayloadAction<string>) => {
      state.devices = state.devices.filter(({ device_code }) => device_code !== action.payload);
    },
  },
});

export const { setDevices, deleteDevice } = slice.actions;

export default slice.reducer;
