import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import {
  AssignDeviceRequest,
  AssignDeviceResponse,
  DeleteDeviceRequest,
  DeleteDeviceResponse,
  EditDeviceRequest,
  EditDeviceResponse,
  Device,
  VerifyDeviceRequest,
  VerifyDeviceResponse,
} from '../models/Device.model';
import { urlEncode } from '../utils/urlUtils';

export const devicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDevices: builder.mutation<Device[], void>({
      query: () => ({
        url: Endpoints.devices,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    verifyDevice: builder.mutation<VerifyDeviceResponse, VerifyDeviceRequest>({
      query: (params) => ({
        url: Endpoints.deviceVerify,
        method: 'GET',
        params,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    assignDevice: builder.mutation<AssignDeviceResponse, AssignDeviceRequest>({
      query: (body) => ({
        url: Endpoints.deviceAssign,
        method: 'POST',
        body: urlEncode(body as unknown as Record<string, string>),
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      }),
    }),
    editDevice: builder.mutation<EditDeviceResponse, EditDeviceRequest>({
      query: (body) => ({
        url: Endpoints.deviceEdit,
        method: 'PATCH',
        body: urlEncode(body as unknown as Record<string, string>),
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      }),
    }),
    deleteDevice: builder.mutation<DeleteDeviceResponse, DeleteDeviceRequest>({
      query: (body) => ({
        url: Endpoints.deviceDelete,
        method: 'DELETE',
        body: urlEncode(body as unknown as Record<string, string>),
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDevicesMutation,
  useVerifyDeviceMutation,
  useAssignDeviceMutation,
  useEditDeviceMutation,
  useDeleteDeviceMutation,
} = devicesApi;
