export default {
  phoneRegex: /^(?:07)[0-9]{8}$/g,
  postalCodeRegex: /^\d{6}$/g,
  streetNameRegex: /(.*[a-z]){3}/i,
  streetNumberRegex: /^[a-zA-Z0-9_'-]+$/,
  onlyNumbersRegex: /^[0-9]+$/,
  phoneRegexLogin: /^\d+$/g,
  oldEmailRegex:
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  emailRegex:
    /^(?![._-])([a-z0-9]+[._-]?)+(?<![._-])@(?![.-])([a-z0-9]+[.-])+(?<=\.)[a-z0-9]{2,}$/i,
  packCodeRegex: /^[a-zA-Z0-9]{7,10}/g,
  passwordRegex:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\|\:" "\\\"\'\<\>\,\.\?\;\/\~\!\@\#\`\$\%\^\&\*\_\+\=\-]).{8,}$/g,
  userNameRegex: /^[ A-Za-z_'-]{3,}$/i,
  questionsRegex: /^question_[\d]+/g,
  storyWebformRegex: /^(?!my)\w+$/,
  feedPath: '/',
  productsPath: '/produse',
  campaignPath: '/campanii',
  prizesPath: '/premii',
  collectionPath: '/colectii',
  walletPath: '/portofel',
  pathSeparator: '/',
  spacesRegex: '[ ]{2,}',
  alphaNumeric: /^[a-zA-Z0-9]+$/,
};
