import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from '../../screens/Authentication/slices/extraSlice';
import { RootState } from '../store';

interface InitialState {
  type: 'screen' | 'modal';
  isOpen: boolean;
}

const getInitialState = (): InitialState => ({
  type: 'screen',
  isOpen: false,
});

const confettiSlice = createSlice({
  name: 'confetti',
  initialState: getInitialState(),
  reducers: {
    openConfetti(state) {
      state.isOpen = true;
    },
    closeConfetti(state) {
      state.isOpen = false;
    },
    changeType(state, action) {
      state.type = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, () => {
      return getInitialState();
    });
  },
});

export const selectIsConfettiOpen = (state: RootState) => state.confetti.isOpen;
export const confettiType = (state: RootState) => state.confetti.type;

export const { openConfetti, closeConfetti, changeType } = confettiSlice.actions;
export default confettiSlice.reducer;
