import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';

import { logoutUser } from '../../screens/Authentication/slices/extraSlice';

interface InitialState {
  codeCount: number;
  checkThreshold: number[];
  resetDate: number;
}

const getRandomThresholdArray = (min: number, max: number): number[] => {
  const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
  return [randomValue];
};

const getInitialState = () => ({
  codeCount: 0,
  checkThreshold: getRandomThresholdArray(7, 15),
  resetDate: moment().startOf('day').valueOf(),
});

export const shouldVerifyCodeCount = (codeCount: number, checkThreshold: number[]): boolean => {
  return checkThreshold.includes(codeCount);
};

const codeSlice = createSlice({
  name: 'codes',
  initialState: getInitialState(),
  reducers: {
    addCode(state: InitialState) {
      const now = moment();

      if (now.isAfter(moment(state.resetDate).endOf('day'))) {
        const initialState = getInitialState();
        state.codeCount = initialState.codeCount;
        state.checkThreshold = initialState.checkThreshold;
        state.resetDate = initialState.resetDate;
      }

      state.codeCount += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
  },
});

export const { addCode } = codeSlice.actions;
export default codeSlice.reducer;
