import { View } from 'react-native';

import colors from '../../../config/theme/shared/colors';
import { sharedStyles } from '../../../config/theme/shared.styles';
import { useIsMobile } from '../../hooks/useIsMobile';
import BATIcon from '../BATIcon';
import BATText from './BATText';
import BATButtonNew from './BATButtonNew';
import BATDialog from './BATDialog';

interface BATDialogConfirmationProps {
  headerTitle?: string;
  headerDescription?: string;
  icon?: 'success' | 'error' | 'info';
  bodyTitle?: string;
  bodyText?: string | JSX.Element;
  buttonLeftText?: string;
  buttonRightText?: string;
  visible: boolean;
  freezed?: boolean;
  onDismiss: () => void;
  onHandleLeft?: () => void;
  onHandleRight?: () => void;
}

const BATDialogConfirmation = ({
  headerTitle,
  headerDescription,
  icon,
  bodyTitle,
  bodyText,
  visible,
  freezed,
  onDismiss,
  buttonLeftText,
  buttonRightText,
  onHandleLeft,
  onHandleRight,
}: BATDialogConfirmationProps) => {
  const { isMobile } = useIsMobile();

  const iconType = (type: 'success' | 'error' | 'info' | undefined) => {
    let name;
    let color;
    let style;

    switch (type) {
      case 'success':
        name = 'success-icon';
        color = colors.successIcon;
        style = sharedStyles.batIconSuccess;
        break;

      case 'error':
        name = 'error-icon';
        color = colors.errorIcon;
        style = sharedStyles.batIconDanger;
        break;

      case 'info':
        name = 'info-icon';
        color = colors.warningIcon;
        style = sharedStyles.batIconWarning;
        break;

      default:
        name = 'info-icon';
        color = colors.warningIcon;
        style = sharedStyles.batIconWarning;
        break;
    }

    return (
      <BATIcon
        name={name}
        color={color}
        size={isMobile ? 25 : 30}
        style={[
          sharedStyles.marginBottomDouble,
          { ...style, padding: 12, marginHorizontal: 'auto' },
        ]}
      />
    );
  };

  return (
    <BATDialog
      visible={visible}
      dismissable={!freezed}
      showCloseButton={!freezed}
      onDismiss={onDismiss}
      header={
        !headerTitle && !headerDescription ? undefined : (
          <View style={[sharedStyles.flex]}>
            <BATText type="heading-4" color={colors.grey_title}>
              {headerTitle}
            </BATText>
            <BATText type="caption" color={colors.grey_text}>
              {headerDescription}
            </BATText>
          </View>
        )
      }
      body={
        <View style={{ marginTop: -18, paddingTop: !headerTitle && !headerDescription ? 12 : 0 }}>
          {iconType(icon)}
          <BATText
            type="heading-4"
            color={colors.grey_title}
            customStyle={[sharedStyles.marginBottomDouble, sharedStyles.alignCenter]}>
            {bodyTitle}
          </BATText>
          <BATText
            type="body"
            color={colors.grey_subtitle}
            customStyle={[sharedStyles.alignCenter]}>
            {bodyText}
          </BATText>
        </View>
      }
      footer={
        <View
          style={[
            sharedStyles.widthFull,
            sharedStyles.displayFlexWrap,
            sharedStyles.justifyContentCenter,
            sharedStyles.padding,
            { gap: 16 },
          ]}>
          {!!buttonLeftText && (
            <BATButtonNew
              variant="outlined"
              disabled={freezed}
              handleSubmitButton={onHandleLeft || (() => {})}
              customStyle={{ flex: !!buttonLeftText && !!buttonRightText ? 1 : 'unset' }}>
              {buttonLeftText}
            </BATButtonNew>
          )}
          {!!buttonRightText && (
            <BATButtonNew
              variant="contained"
              disabled={freezed}
              handleSubmitButton={onHandleRight || (() => {})}
              customStyle={{ flex: !!buttonLeftText && !!buttonRightText ? 1 : 'unset' }}>
              {buttonRightText}
            </BATButtonNew>
          )}
        </View>
      }
    />
  );
};

export default BATDialogConfirmation;
