import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../config/api';
import { GetAllStoriesResponse } from '../../../models/Stories.model';
import { logoutUser } from '../../Authentication/slices/extraSlice';
import { createDraft } from 'immer';

type StoriesState = {
  stories: GetAllStoriesResponse[] | null;
};

const getInitialState = (): StoriesState => ({ stories: null });

type UpdateStoryFlagPayload = {
  field_survey: string;
};

const slice = createSlice({
  name: 'stories',
  initialState: getInitialState(),
  reducers: {
    resetStoriesState: (state) => {
      state.stories = null;
    },
    updateStoryFlag(state, action: PayloadAction<UpdateStoryFlagPayload>) {
      if (state.stories) {
        const { field_survey } = action.payload;

        state.stories = state.stories.map((bubble) => {
          return {
            ...bubble,
            stories: bubble.stories.map((story) => {
              if (story.field_survey === field_survey) {
                return {
                  ...story,
                  voted: true,
                };
              }
              return story;
            }),
          };
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
    builder.addMatcher(api.endpoints.getStories.matchFulfilled, (state, { payload }) => {
      state.stories = createDraft(payload as GetAllStoriesResponse[]);
      for (const bubble of state.stories) {
        for (const story of bubble.stories) {
          if (story.field_video_link !== '' && story.field_video_link != null) {
            story.duration = 60000;
          }
        }
      }
    });
  },
});

export const { resetStoriesState, updateStoryFlag } = slice.actions;

export default slice.reducer;
