import Constants from '../config/Constants';

export const removeWhitespace = (str: string): string => {
  return str.replace(new RegExp(Constants.spacesRegex, 'gim'), '');
};

export const isNullOrEmptyString = (str: string | null | undefined) => {
  return str === '' || str == null;
};

export const trimAndLowerCase = (str: string | null | undefined) => {
  if (str == null) return str;
  return str.toLocaleLowerCase?.()?.trim();
};

export const base64Encode = (value: string) => {
  return btoa(
    new TextEncoder().encode(value).reduce((data, byte) => data + String.fromCharCode(byte), ''),
  );
};

export const base64Decode = (encodedValue: string) => {
  const byteCharacters = atob(encodedValue);
  const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  const decoder = new TextDecoder();
  return decoder.decode(byteArray);
};

export const removeHtmlTags = (htmlText: string) => {
  return htmlText.replace(/<\/?[^>]+(>|$)/g, '');
};
