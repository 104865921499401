import Cookies from 'js-cookie';
import { ONE_TRUST_UUID } from 'react-native-dotenv';

const addGTM = () => {
  const script = document.createElement('script');
  script.setAttribute('id', 'bat-gtm');
  script.innerHTML = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TQXGB9L');
    `;
  document.head.append(
    document.createComment(' Google Tag Manager '),
    script,
    document.createComment(' End Google Tag Manager '),
  );
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQXGB9L"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
  document.body.append(
    document.createComment(' Google Tag Manager (noscript) '),
    script,
    document.createComment(' End Google Tag Manager (noscript) '),
  );
  window.addEventListener('appinstalled', () => {
    window.dataLayer.push({
      event: 'app_install',
    });
  });
};

const toObject = (params: URLSearchParams): Record<string, string | null> => {
  const rv: Record<string, string | null> = {};
  params.forEach((value, key) => {
    rv[key] = value;
  });
  return rv;
};

const domains = (() => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  const base = parts.splice(parts.length - 2).join('.');
  return [hostname, base, `.${base}`];
})();

function OptanonWrapper() {
  const consent = toObject(new URLSearchParams(Cookies.get('OptanonConsent')));
  const groups = consent.groups?.split(',') ?? [];
  if (groups.includes('C0002:0')) {
    const cookies = Cookies.get();
    Object.keys(cookies)
      .filter((key) => key.startsWith('_ga'))
      .forEach((key) => {
        Cookies.remove(key);
        domains.forEach((domain) => {
          Cookies.remove(key, { domain });
        });
      });
    if (document.getElementById('bat-gtm') != null) {
      window.location.reload();
    }
  }
  if (groups.includes('C0002:1')) {
    addGTM();
  }

  // layout change with 3 buttons in the footer
  function e() {
    var e = document.getElementById('accept-recommended-btn-handler');
    document.getElementsByClassName('ot-btn-container')[0].prepend(e);
  }
  if (Optanon.IsAlertBoxClosed() == 0) Optanon.ToggleInfoDisplay(), e();
  else {
    var t = document.getElementsByClassName('ot-floating-button__open')[0];
    t.addEventListener('click', function (n) {
      e();
    });
  }

  // Refresh page after user accepts or saves cookie settings
  const acceptRecommendedBtn = document.getElementById('accept-recommended-btn-handler');
  const savePreferenceBtn = document.getElementsByClassName(
    'save-preference-btn-handler onetrust-close-btn-handler',
  )[0];
  if (acceptRecommendedBtn) {
    acceptRecommendedBtn.addEventListener('click', () => {
      window.location.reload();
    });
  }
  if (savePreferenceBtn) {
    savePreferenceBtn.addEventListener('click', () => {
      // Quick timeout so that the consent receipt can be sent and the cookie can be updated
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }
}

const addOneTrust = () => {
  (window as any).OptanonWrapper = OptanonWrapper;
  const script = document.createElement('script');
  script.setAttribute('src', 'text/javascript');
  script.setAttribute('src', `https://cdn.cookielaw.org/consent/${ONE_TRUST_UUID}/OtAutoBlock.js`);
  script.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
  script.setAttribute('charset', 'UTF-8');
  script.setAttribute('data-domain-script', ONE_TRUST_UUID);

  document.head.append(
    document.createComment(' OneTrust Cookies Consent Notice start for one-up.me '),
    script,
    document.createComment(' OneTrust Cookies Consent Notice end for one-up.me '),
  );
};

export const register = () => {
  addOneTrust();
  // addGTM();
};
