import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { logoutUser } from '../../Authentication/slices/extraSlice';
import { CardProps } from '../../../src/components/BATCard';
import {
  BundleDeliveryMethods,
  BundleOptionUi,
  BundleSuboptionUi,
  PrizeUi,
} from '../../../models/Prize.model';
import { PromotedItem } from '../../../models/Promoted.model';
import { minFieldPoints, sum } from '../components/utils';
import { BATListItemsSelectorItem } from '../../../src/components/common/BATListItemsSelector';

export enum ANCHOR_PRIZES {
  LIMITED_NCP_1 = 'premiul-cel-mare',
  LIMITED_NCP_2 = 'premii-saptamanale',
  LIMITED_NCP_3 = 'premii-zilnice',
  GUARANTEED = 'loialitate',
  LUCKY = 'momente-norocose',
}

export enum LIMITED_PRIZES_TYPE {
  LIMITED_NCP_1_TYPE = 'big',
  LIMITED_NCP_2_TYPE = 'weekly',
  LIMITED_NCP_3_TYPE = 'daily',
}

interface BrandProps {
  nid: string;
  name: string;
}

interface NCPType {
  type?: LIMITED_PRIZES_TYPE;
}

export interface LimitedPrizesUI extends NCPType {
  title: string;
  prizes: CardProps[];
  priority: number;
}

interface CategoryProps {
  categoryName: string;
  categoryId: string;
  categoryIcon: string;
}

interface GuaranteedPrize extends CategoryProps {
  prizes: CardProps[];
}

const formatDate = (date: string) => {
  return `Disponibil din ${moment(date, 'YYYY/MM/DD', 'ro').format('DD MMM YYYY')}`;
};

export const selectVariant = (
  chances: number | undefined,
  stock: number | undefined,
  comingSoon: boolean,
) => {
  if (comingSoon) {
    return 'default';
  }
  if (stock === 0 || (chances !== undefined && chances >= 0))
    return stock === 0 ? 'danger' : chances !== undefined && chances > 0 ? 'success' : 'warning';
  return undefined;
};

export const formatLabel = (
  chances: number | undefined,
  stock: number | undefined,
  comingSoon: boolean,
) => {
  if (comingSoon) {
    return `În curând`;
  }
  if (stock === 0 || (chances !== undefined && chances >= 0))
    return stock === 0
      ? 'Stoc epuizat'
      : chances === 0
      ? 'Alocă șanse'
      : chances === 1
      ? '1 șansă'
      : `${chances} șanse`;
  return undefined;
};

export const formatDueDate = (period: string) => {
  let endDate;

  const periodAsArray = period.split(' - ');
  if (periodAsArray.length === 1) {
    endDate = periodAsArray[0];
  } else {
    endDate = periodAsArray[1];
  }

  const endMoment = moment(endDate, 'YYYY-MM-DD');
  const adjustedEndMoment = endMoment.add(1, 'days');

  const currentMoment = moment();
  const duration = moment.duration(adjustedEndMoment.diff(currentMoment));

  let totalHours = duration.asHours();
  const days = Math.floor(totalHours / 24).toString();
  totalHours %= 24;

  const hours = Math.floor(totalHours).toString();
  const minutes = duration.minutes().toString();

  return {
    days,
    hours,
    minutes,
  };
};

const formatDiscountedLabel = (data: any) => {
  if (
    !Number(data.final_activity_points) ||
    !Number(data.final_loyalty_points) ||
    !data.discounted_end_date ||
    !data.discounted_start_date
  ) {
    return {};
  }

  const now = moment(moment(), 'DD/MM/YYYY');
  const expire = moment(data.discounted_end_date, 'DD/MM/YYYY').add(1, 'days');
  const isInFuture = moment(data.discounted_start_date, 'DD/MM/YYYY');

  if (now.isAfter(expire) || now.isBefore(isInFuture)) {
    return {};
  }

  const expireAt = expire.diff(now, 'days');
  const duration = moment.duration(expire.diff(now));

  const hours = duration.hours();
  const minutes = duration.minutes();

  const discountLabel =
    expireAt === 0
      ? `Oferta expiră în ${hours}h ${minutes}m`
      : expireAt === 1
      ? `Oferta expiră mâine`
      : `Oferta expiră în ${expireAt} zile`;

  return {
    discountedLabel: {
      icon: 'clock',
      label: discountLabel,
    },
  };
};

export const formatDiscountProps = (data: any, prizeType?: string) => {
  if (
    (prizeType !== 'garantat' && !prizeType?.includes('guaranteed') && prizeType !== 'bundle') ||
    !Number(data.final_activity_points) ||
    !Number(data.final_loyalty_points) ||
    !data.discounted_end_date ||
    Number(data.set_discounted_price) === 0
  ) {
    return {
      discountedActivityPoints: undefined,
      discountedLoyaltyPoints: undefined,
    };
  }
  const discountLabel = formatDiscountedLabel(data);

  if (Object.keys(discountLabel).length) {
    return {
      discountedActivityPoints: Number(data.final_activity_points),
      discountedLoyaltyPoints: Number(data.final_loyalty_points),
      ...discountLabel,
    };
  }

  return {
    discountedActivityPoints: undefined,
    discountedLoyaltyPoints: undefined,
  };
};

const formatPrizeData = (
  data: any,
  prizeType: 'limitat' | 'moment' | 'garantat' | 'bundle',
  comingSoon?: boolean,
): CardProps => {
  return {
    nid: data.nid,
    image: data.field_image ?? data.bundle_image,
    title: data.title,
    brand: data.brand ?? data.field_brand,
    date: data.date,
    activityPoints:
      'field_activity_points' in data && Number(data.field_activity_points) > 0
        ? Number(data.field_activity_points)
        : undefined,
    loyaltyPoints:
      'field_pack_points' in data && Number(data.field_pack_points) > 0
        ? Number(data.field_pack_points)
        : undefined,
    badge: {
      variant: selectVariant(
        'chances' in data ? Number(data.chances) : undefined,
        'field_stock' in data && (data.field_stock === '' || Number(data.field_stock) === 0)
          ? 0
          : Number(data.field_stock) > 0
          ? Number(data.field_stock)
          : undefined,
        !!comingSoon,
      ),
      label: formatLabel(
        'chances' in data ? Number(data.chances) : undefined,
        data.field_stock === '' || Number(data.field_stock) === 0
          ? 0
          : Number(data.field_stock) > 0
          ? Number(data.field_stock)
          : undefined,
        !!comingSoon,
      ),
    },
    stock:
      'field_stock' in data && (data.field_stock === '' || Number(data.field_stock) === 0)
        ? 0
        : Number(data.field_stock) > 0
        ? Number(data.field_stock)
        : undefined,
    ncp: 'ncp' in data ? data.ncp : undefined,
    url_alias: !comingSoon
      ? `/premiu/${prizeType === 'bundle' ? 'garantat' : prizeType}${
          prizeType === 'bundle' ? '/bundle' : ''
        }${data.url_alias}`
      : undefined,
    isBundle: prizeType === 'bundle',
    displayPoints: data.displayPoints ?? 'default',
    isFavorite: Number(data.is_favorite) === 1,
    ...formatDiscountProps(data, prizeType),
    dueDate:
      !('comingSoon' in data) && 'prize_period' in data
        ? {
            ...formatDueDate(data.prize_period),
            iconName: 'clock',
            iconSize: 20,
            iconCustomStyles: {},
          }
        : undefined,
  };
};

const getInitialState = () => ({
  brands: [] as BrandProps[],
  specialCategories: {} as Record<string, CategoryProps[]>,
  promotedPrizes: [] as PromotedItem[],
  limitedPrizes: {} as Record<string, LimitedPrizesUI[]>,
  luckyMomentPrizes: {} as Record<string, CardProps[]>,
  luckyMomentPrizesComingSoon: {} as Record<string, CardProps[]>,
  guaranteedPrizes: {} as Record<string, GuaranteedPrize[]>,
  guaranteedPrizesComingSoon: {} as Record<string, GuaranteedPrize[]>,
  prizes: {} as Record<string, PrizeUi>,
});

export const getPrizeSectionName = (key: string): string | undefined => {
  return ANCHOR_PRIZES[key as keyof typeof ANCHOR_PRIZES];
};

export const getNCPNumber = (key: string | undefined) => {
  switch (key) {
    case LIMITED_PRIZES_TYPE.LIMITED_NCP_1_TYPE:
      return 'NCP_1';
    case LIMITED_PRIZES_TYPE.LIMITED_NCP_2_TYPE:
      return 'NCP_2';
    case LIMITED_PRIZES_TYPE.LIMITED_NCP_3_TYPE:
      return 'NCP_3';
    default:
      return undefined;
  }
};

const prizesSlice = createSlice({
  name: 'prizes',
  initialState: getInitialState(),
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload.map((brand: any) => ({
        nid: brand.tid,
        name: brand.name,
      }));

      action.payload.forEach((brand: any) => {
        state.limitedPrizes[brand.name] = [];
        state.guaranteedPrizes[brand.name] = [];
        state.guaranteedPrizesComingSoon[brand.name] = [];
        state.specialCategories[brand.name] = [];
        if (brand?.categories) {
          brand.categories.forEach((category: any) => {
            const prizeObject = {
              categoryName: category.category,
              categoryId: category.category_id,
              categoryIcon: category.category_icon,
              prizes: [],
            };

            if (category.special_category === '' || category.special_category === '0') {
              state.guaranteedPrizes[brand.name].push(prizeObject);
              state.guaranteedPrizesComingSoon[brand.name].push(prizeObject);
            } else {
              state.guaranteedPrizes[brand.name].unshift(prizeObject);
              state.guaranteedPrizesComingSoon[brand.name].unshift(prizeObject);

              state.specialCategories[brand.name].push({
                categoryName: category.category,
                categoryId: category.category_id,
                categoryIcon: category.category_icon,
              });
            }
          });
        }
        state.luckyMomentPrizes[brand.name] = [];
        state.luckyMomentPrizesComingSoon[brand.name] = [];
      });
    },
    setPromotedPrizes: (state, action) => {
      state.promotedPrizes = action.payload;
    },
    setLimitedPrizes: (state, action) => {
      const limitedPrizesData = action.payload.data;
      const brand = action.payload.brand;
      state.limitedPrizes[brand] = [];

      const groupedData = limitedPrizesData.reduce(
        (acc: Record<string, LimitedPrizesUI>, prize: any) => {
          const { field_limited_prize_type, campaign_title } = prize;
          const key = `${field_limited_prize_type}_${campaign_title}`;
          const priority = +prize.campaign_priority;

          if (!acc[key]) {
            acc[key] = {
              type: field_limited_prize_type,
              title: campaign_title,
              prizes: [],
              priority: Number.isNaN(priority) ? 0 : priority,
            };
          }

          const formattedPrize = formatPrizeData(
            {
              ...prize,
              brand,
              date:
                'comingSoon' in prize && 'prize_period' in prize
                  ? formatDate(prize.prize_period.split(' - ')[0])
                  : undefined,
            },
            'limitat',
            'comingSoon' in prize && prize.comingSoon,
          );

          if (!acc[key].prizes.some((p) => p.nid === prize.nid && !prize.comingSoon)) {
            acc[key].prizes.push(formattedPrize);
          }

          return acc;
        },
        {},
      );
      state.limitedPrizes[brand] = Object.values(groupedData);
    },
    setLuckyMomentPrizes: (state, action) => {
      const luckyMomentPrizesData = action.payload.data;
      const brand = action.payload.brand;

      state.luckyMomentPrizes[brand] = [];

      luckyMomentPrizesData.forEach((prizeData: any) => {
        state.luckyMomentPrizes[brand].push(
          formatPrizeData(
            {
              ...prizeData,
              field_image: `${process.env.API_URL}${prizeData.image}`,
              brand,
            },
            'moment',
          ),
        );
      });
    },
    setLuckyMomentPrizesComingSoon: (state, action) => {
      const luckyMomentPrizesData = action.payload.data;
      const brand = action.payload.brand;

      state.luckyMomentPrizesComingSoon[brand] = [];

      luckyMomentPrizesData.forEach((prizeData: any) => {
        state.luckyMomentPrizesComingSoon[brand].push(
          formatPrizeData(
            {
              ...prizeData,
              field_image: `${process.env.API_URL}${prizeData.image}`,
              date: 'start_date' in prizeData && formatDate(prizeData.start_date),
              brand,
            },
            'moment',
            true,
          ),
        );
      });
    },
    setGuaranteedPrizes: (state, action) => {
      const { guaranteedPrizes, bundlePrizes } = action.payload.data;
      const brand = action.payload.brand;
      const category = action.payload.category;

      const existingCategoryIndex = state.guaranteedPrizes[brand].findIndex(
        (item) => item.categoryName === category,
      );

      if (existingCategoryIndex !== -1) {
        state.guaranteedPrizes[brand][existingCategoryIndex].prizes = [];

        const isNcpCategory = state.specialCategories[brand].find(
          (specialCategory) => specialCategory.categoryName === category,
        );

        guaranteedPrizes.forEach((prizeData: any) => {
          const isNcpPrize = state.specialCategories[brand].find((specialCategory) =>
            prizeData.prize_categories.includes(specialCategory.categoryName),
          );

          state.guaranteedPrizes[brand][existingCategoryIndex].prizes.push(
            formatPrizeData(
              {
                ...prizeData,
                brand,
                ncp: isNcpPrize && !isNcpCategory,
              },
              'garantat',
            ),
          );
        });

        bundlePrizes.forEach((prizeData: any) => {
          const isNcpPrize = state.specialCategories[brand].find((specialCategory) =>
            prizeData.prize_categories.includes(specialCategory.categoryName),
          );

          state.guaranteedPrizes[brand][existingCategoryIndex].prizes.push(
            formatPrizeData(
              {
                ...prizeData,
                brand,
                ncp: isNcpPrize && !isNcpCategory,
                ...minFieldPoints({ ...prizeData }),
              },
              'bundle',
            ),
          );
        });

        const prizesFromBE = state.guaranteedPrizes[brand][existingCategoryIndex].prizes;
        const prizesWithStock = prizesFromBE.filter((prize) => prize.stock !== 0);
        const prizesWithoutStock = prizesFromBE.filter((prize) => prize.stock === 0);
        const sortedPrizes = [...prizesWithStock, ...prizesWithoutStock];
        state.guaranteedPrizes[brand][existingCategoryIndex].prizes = sortedPrizes;
      }
    },
    setGuaranteedPrizesComingSoon: (state, action) => {
      const comingSoonGuaranteedPrizesData = action.payload.data;
      const brand = action.payload.brand;

      state.guaranteedPrizesComingSoon[brand].forEach((_, index) => {
        state.guaranteedPrizesComingSoon[brand][index].prizes = [];
      });

      comingSoonGuaranteedPrizesData.forEach((prizeData: any) => {
        const { prize_categories } = prizeData;
        const prizeCategoriesAsArray = prize_categories.split(', ');
        const uniquePrizeCategories = [...new Set(prizeCategoriesAsArray)];

        for (const category of uniquePrizeCategories) {
          const existingCategoryIndex = state.guaranteedPrizesComingSoon[brand].findIndex(
            (item) => item.categoryName === category,
          );

          if (existingCategoryIndex !== -1) {
            const isNcpCategory = state.specialCategories[brand].find(
              (specialCategory) => specialCategory.categoryName === category,
            );
            const isNcpPrize = state.specialCategories[brand].find((specialCategory) =>
              prizeData.prize_categories.includes(specialCategory.categoryName),
            );
            state.guaranteedPrizesComingSoon[brand][existingCategoryIndex].prizes.push(
              formatPrizeData(
                {
                  ...prizeData,
                  date: formatDate(prizeData.field_start_date),
                  brand,
                  ncp: isNcpPrize && !isNcpCategory,
                },
                'garantat',
                true,
              ),
            );
          }
        }
      });
    },
    increaseChances: (state, action) => {
      const prizeData = action.payload.prizeData;
      const brand = action.payload.brand;
      const { field_limited_prize_type } = prizeData;

      if (field_limited_prize_type && state.limitedPrizes[brand]) {
        state.limitedPrizes[brand].forEach((campaign) => {
          const existingPrizeIndex = campaign.prizes.findIndex(
            (prize) => prize?.nid?.toString() === prizeData.nid.toString(),
          );

          if (existingPrizeIndex !== -1) {
            campaign.prizes[existingPrizeIndex] = {
              ...campaign.prizes[existingPrizeIndex],
              badge: {
                variant: selectVariant(prizeData.chances, undefined, false),
                label: formatLabel(prizeData.chances, undefined, false),
              },
            };
          }
        });
      }
      const targetedPromotedPrize = state.promotedPrizes.find(({ nid }) => +nid === prizeData.nid);
      if (targetedPromotedPrize) {
        targetedPromotedPrize.chances = prizeData.chances;
      }
    },
    setPrize: (state, action) => {
      const prizeData = action.payload.data[0];
      const prizeSlug = action.payload.prizeSlug;
      const prizeType = action.payload.prizeType; // expectation: bundle/garantat/moment/limitat
      const prizeVariations = action.payload.prizeVariations;

      const {
        field_activity_points,
        field_pack_points,
        field_delivery_method,
        displayPoints,
        final_activity_points,
        final_loyalty_points,
        discounted_end_date,
      } = minFieldPoints(prizeData);

      state.prizes[prizeSlug] = {
        prizeAsCardData: formatPrizeData(
          {
            ...prizeData,
            field_activity_points,
            field_pack_points,
            field_delivery_method,
            displayPoints,
            final_activity_points,
            final_loyalty_points,
            discounted_end_date,
          },
          prizeType,
        ),
        prizeDetails: {
          id: prizeData.nid,
          title: prizeData.title,
          image: prizeType === 'bundle' ? prizeData.bundle_image : prizeData.field_image,
          description: prizeData.body,
          video: prizeData.field_video_link,
          requirements:
            prizeType !== 'moment'
              ? {
                  //LOYALTY POINTS
                  minLoyaltyPoints: final_loyalty_points || field_pack_points,
                  userHasLoyaltyPoints:
                    'loyalty_points' in prizeData.can_redeem
                      ? prizeData.can_redeem.loyalty_points
                      : prizeData.field_delivery_method === BundleDeliveryMethods.Shipping
                      ? prizeData.can_redeem.shipping_loyalty_points
                      : prizeData.field_delivery_method === BundleDeliveryMethods.Pickup
                      ? prizeData.can_redeem.pickup_loyalty_points
                      : prizeData.can_redeem.pickup_loyalty_points ||
                        prizeData.can_redeem.shipping_loyalty_points,

                  // ACTIVITY POINTS
                  minActivityPoints: final_activity_points || field_activity_points,
                  userHasActivityPoints:
                    'activity_points' in prizeData.can_redeem
                      ? prizeData.can_redeem.activity_points
                      : prizeData.field_delivery_method === BundleDeliveryMethods.Shipping
                      ? prizeData.can_redeem.shipping_activity_points
                      : prizeData.field_delivery_method === BundleDeliveryMethods.Pickup
                      ? prizeData.can_redeem.pickup_activity_points
                      : prizeData.can_redeem.pickup_activity_points ||
                        prizeData.can_redeem.shipping_activity_points,

                  //BRAND POINTS
                  minBrandPoints: Number(prizeData.field_minimum_pack_codes),
                  userBrandPoints:
                    typeof prizeData.can_redeem.pack_codes === 'boolean'
                      ? prizeData.can_redeem.pack_codes
                      : Number(prizeData.can_redeem.pack_codes),
                  restrictToBrandPoints: Number(prizeData.field_restrict_to_brand_points) === 1,
                  restrictToPackPoints: Number(prizeData.field_restrict_to_pack_codes) === 1,
                  brandPointsMessage: prizeData.field_minimum_pack_codes_message,

                  brand: prizeData.field_brand,
                  canAccessHiddenOffer: prizeData.can_redeem.can_access_hidden_offer,
                  userIsEligibleRulesQuantity: prizeData.can_redeem.eligible_rules_qty,
                  userIsEligibleRulesDays: prizeData.can_redeem.eligible_rules_days,

                  canRedeemResult: prizeData.can_redeem.result,

                  restrictYoti:
                    prizeData.field_restrict_yoti_age_verified === 1 &&
                    !prizeData.can_redeem.is_age_verified,
                  //UGC PHOTOS
                  restrictToUgcPhotos: +prizeData.field_restrict_to_ugc_photos === 1,
                  approvedUgcPhotos: prizeData.field_approved_ugc_photos,
                  userUgcPhotos: prizeData?.can_redeem.ugc_photos ?? [],
                  userHasNecessaryPictures: prizeData?.can_redeem.approved_ugc_photos,
                  //GLO SUBSCRIBER
                  restrictToGloSubscriber: +prizeData.field_restrict_to_glo_subscriber === 1,
                  userIsGloSubscriber: prizeData.can_redeem.glo_subscriber,
                }
              : undefined,
          deliveryMethod: prizeData.field_delivery_method,
          nrOfDays: prizeData.field_nr_of_days,
          nrOfPrizes: prizeData.field_nr_of_prizes,
          isOutOfStock: Number(prizeData.field_stock) === 0,
        },
      };

      if (prizeType === 'bundle') {
        if ('field_delivery_method' in prizeData) {
          if (prizeData.field_delivery_method !== BundleDeliveryMethods.Pickup) {
            state.prizes[prizeSlug].prizeShippingDeliveryDetails = {
              title: 'Livrare acasă',
              image:
                prizeData.shipping_image !== ''
                  ? prizeData.shipping_image
                  : require('../../../assets/images/placeholders/shipping_placeholder.png'),
              description: prizeData.field_shipping_description,
              redeemSummary: prizeData.field_shipping_redeem_summary,
              deliveryMethod: BundleDeliveryMethods.Shipping,
              requirements: {
                //LOYALTY POINTS
                minLoyaltyPoints: sum(
                  prizeData.field_pack_points,
                  prizeData.field_shipping_extra_loyalty,
                ),
                userHasLoyaltyPoints: prizeData.can_redeem.shipping_loyalty_points,
                // ACTIVITY POINTS
                minActivityPoints: sum(
                  prizeData.field_activity_points,
                  prizeData.field_shipping_extra_activity,
                ),
                userHasActivityPoints: prizeData.can_redeem.shipping_activity_points,

                //BRAND POINTS
                minBrandPoints: Number(prizeData.field_minimum_pack_codes),
                userBrandPoints:
                  typeof prizeData.can_redeem.pack_codes === 'boolean'
                    ? prizeData.can_redeem.pack_codes
                    : Number(prizeData.can_redeem.pack_codes),
                restrictToBrandPoints: Number(prizeData.field_restrict_to_brand_points) === 1,
                restrictToPackPoints: Number(prizeData.field_restrict_to_pack_codes) === 1,
                brandPointsMessage: prizeData.field_minimum_pack_codes_message,
                brand: prizeData.field_brand,
                canAccessHiddenOffer: prizeData.can_redeem.can_access_hidden_offer,
                userIsEligibleRulesQuantity: prizeData.can_redeem.eligible_rules_qty,
                userIsEligibleRulesDays: prizeData.can_redeem.eligible_rules_days,
                canRedeemResult: prizeData.can_redeem.result_shipping,
                ...formatDiscountProps(
                  {
                    final_activity_points: prizeData.final_activity_points_shipping,
                    final_loyalty_points: prizeData.final_loyalty_points_shipping,
                    discounted_end_date: prizeData.discounted_end_date_shipping,
                    discounted_start_date: prizeData.discounted_start_date_shipping,
                    set_discounted_price: prizeData.set_discounted_price,
                  },
                  'bundle',
                ),

                //UGC PHOTOS
                restrictToUgcPhotos: +prizeData.field_restrict_to_ugc_photos === 1,
                approvedUgcPhotos: prizeData.field_approved_ugc_photos,
                userUgcPhotos: prizeData?.can_redeem.ugc_photos ?? [],
                userHasNecessaryPictures: prizeData?.can_redeem.approved_ugc_photos,
                //GLO SUBSCRIBER
                restrictToGloSubscriber: +prizeData.field_restrict_to_glo_subscriber === 1,
                userIsGloSubscriber: prizeData.can_redeem.glo_subscriber,
              },
            };
          }

          if (prizeData.field_delivery_method !== BundleDeliveryMethods.Shipping) {
            state.prizes[prizeSlug].prizePickupDeliveryDetails = {
              title: 'Ridicare din magazin',
              image:
                prizeData.pickup_image !== ''
                  ? prizeData.pickup_image
                  : require('../../../assets/images/placeholders/pickup_placeholder.png'),
              description: prizeData.field_pickup_description,
              redeemSummary: prizeData.field_pickup_redeem_summary,
              deliveryMethod: BundleDeliveryMethods.Pickup,
              requirements: {
                //LOYALTY POINTS
                minLoyaltyPoints: sum(
                  prizeData.field_pack_points,
                  prizeData.field_pickup_extra_loyalty,
                ),
                userHasLoyaltyPoints: prizeData.can_redeem.pickup_loyalty_points,
                // ACTIVITY POINTS
                minActivityPoints: sum(
                  prizeData.field_activity_points,
                  prizeData.field_pickup_extra_activity,
                ),
                userHasActivityPoints: prizeData.can_redeem.pickup_activity_points,

                //BRAND POINTS
                minBrandPoints: Number(prizeData.field_minimum_pack_codes),
                userBrandPoints:
                  typeof prizeData.can_redeem.pack_codes === 'boolean'
                    ? prizeData.can_redeem.pack_codes
                    : Number(prizeData.can_redeem.pack_codes),
                restrictToBrandPoints: Number(prizeData.field_restrict_to_brand_points) === 1,
                restrictToPackPoints: Number(prizeData.field_restrict_to_pack_codes) === 1,
                brandPointsMessage: prizeData.field_minimum_pack_codes_message,
                brand: prizeData.field_brand,
                canAccessHiddenOffer: prizeData.can_redeem.can_access_hidden_offer,
                userIsEligibleRulesQuantity: prizeData.can_redeem.eligible_rules_qty,
                userIsEligibleRulesDays: prizeData.can_redeem.eligible_rules_days,
                canRedeemResult: prizeData.can_redeem.result_pickup,
                ...formatDiscountProps(
                  {
                    final_activity_points: prizeData.final_activity_points_pickup,
                    final_loyalty_points: prizeData.final_loyalty_points_pickup,
                    discounted_end_date: prizeData.discounted_end_date_pickup,
                    discounted_start_date: prizeData.discounted_start_date_pickup,
                    set_discounted_price: prizeData.set_discounted_price,
                  },
                  'bundle',
                ),

                //UGC PHOTOS
                restrictToUgcPhotos: +prizeData.field_restrict_to_ugc_photos === 1,
                approvedUgcPhotos: prizeData.field_approved_ugc_photos,
                userUgcPhotos: prizeData?.can_redeem.ugc_photos ?? [],
                userHasNecessaryPictures: prizeData?.can_redeem.approved_ugc_photos,
                //GLO SUBSCRIBER
                restrictToGloSubscriber: +prizeData.field_restrict_to_glo_subscriber === 1,
                userIsGloSubscriber: prizeData.can_redeem.glo_subscriber,
              },
            };
          }
        }

        state.prizes[prizeSlug].bundleGroups = prizeData.bundle_groups.map(
          ({
            id,
            field_delivery_method,
            field_title,
            bundle_options,
            field_quantity,
            field_batch_quantity,
            field_input_type,
            field_is_gift,
          }: any) => ({
            id,
            deliveryMethod: field_delivery_method,
            title: field_title,
            subtitle: bundle_options.length === 1 ? '' : 'Alege un produs',
            imageUrl:
              bundle_options.length === 1 ? bundle_options[0].thumbnail__target_id : undefined,
            quantity: field_quantity,
            batchQuantity: field_batch_quantity,
            selectorType: field_input_type,
            bundleOptions: bundle_options.map(
              ({ title, thumbnail__target_id, options, field_stock }: any) => ({
                title,
                imageUrl: thumbnail__target_id,
                suboptions: options.map(
                  ({ id, field_option, field_media_image, field_variation, field_stock }: any) => ({
                    id,
                    title: field_option,
                    imageUrl: field_media_image,
                    variations: !prizeVariations
                      ? []
                      : prizeVariations.filter(({ variation_id }: any) =>
                          field_variation.split(', ').includes(variation_id),
                        ),
                    stock: +field_stock,
                    selectedQuantity: 0,
                  }),
                ),
                stock: field_stock,
                selected: bundle_options.length === 1,
                listItemsSelectorsSelectedItems: [],
              }),
            ),
            completed: false,
            isOutOfStock: bundle_options.every(({ options }: any) => {
              const possibleTotal = options.reduce(
                (possibleTotal: number, { field_stock }: any) => {
                  if (field_input_type === 'multiple_select') {
                    return possibleTotal + Math.floor(+field_stock / field_batch_quantity);
                  }

                  return possibleTotal + (+field_stock >= field_batch_quantity ? 1 : 0);
                },
                0,
              );
              return possibleTotal < field_quantity;
            }),
            isGift: field_is_gift === 1,
          }),
        );
      }
    },
    markBundleOptionAsSelected: (
      state,
      action: {
        payload: { prizeSlug: string; bundleGroupId: number; bundleOption: BundleOptionUi };
      },
    ) => {
      const { prizeSlug, bundleGroupId, bundleOption } = action.payload;
      const targetedBundleGroup = state.prizes[prizeSlug].bundleGroups?.find(
        ({ id }) => id === bundleGroupId,
      );

      if (!targetedBundleGroup) {
        return;
      }

      targetedBundleGroup.bundleOptions = targetedBundleGroup.bundleOptions.map((option) => ({
        ...option,
        selected: option.title === bundleOption.title,
        suboptions: option.suboptions.map((suboption) => ({ ...suboption, selectedQuantity: 0 })),
      }));
      targetedBundleGroup.subtitle = bundleOption.title;
      targetedBundleGroup.imageUrl = bundleOption.imageUrl;
    },
    updateListItemsSelectorsSelectedItems: (
      state,
      action: {
        payload: {
          prizeSlug: string;
          bundleGroupId: number;
          bundleOption: BundleOptionUi;
          listItemsSelectorIndex: number;
          listItemsSelectorSelectedItem: BATListItemsSelectorItem;
          listItemsSelectorsLength: number;
        };
      },
    ) => {
      const {
        prizeSlug,
        bundleGroupId,
        bundleOption,
        listItemsSelectorIndex,
        listItemsSelectorSelectedItem,
        listItemsSelectorsLength,
      } = action.payload;
      const targetedBundleGroup = state.prizes[prizeSlug].bundleGroups?.find(
        ({ id }) => id === bundleGroupId,
      );

      if (!targetedBundleGroup) {
        return;
      }

      const targetedBundleOption = targetedBundleGroup.bundleOptions.find(
        ({ title }) => title === bundleOption.title,
      );

      if (!targetedBundleOption) {
        return;
      }

      targetedBundleOption.listItemsSelectorsSelectedItems = [
        ...targetedBundleOption.listItemsSelectorsSelectedItems.slice(0, listItemsSelectorIndex),
        listItemsSelectorSelectedItem,
      ];
      targetedBundleGroup.subtitle = targetedBundleOption.listItemsSelectorsSelectedItems
        .map(({ label }) => label)
        .join(' · ');
      targetedBundleGroup.completed =
        targetedBundleOption.listItemsSelectorsSelectedItems.length === listItemsSelectorsLength;

      if (targetedBundleGroup.completed) {
        const targetedSuboption = targetedBundleOption.suboptions.find(({ variations }) =>
          targetedBundleOption.listItemsSelectorsSelectedItems.every(({ value }) =>
            variations.some(({ variation_id }) => variation_id === value),
          ),
        )!;
        targetedBundleOption.suboptions = targetedBundleOption.suboptions.map((suboption) => ({
          ...suboption,
          selectedQuantity: suboption.id === targetedSuboption.id ? 1 : 0,
        }));
      }
    },
    updateBundleSuboptionsSelectedQuantities: (
      state,
      action: {
        payload: {
          prizeSlug: string;
          bundleGroupId: number;
          bundleOption: BundleOptionUi;
          bundleOptionSelectionResult: { item: BundleSuboptionUi; count: number }[];
        };
      },
    ) => {
      const { prizeSlug, bundleGroupId, bundleOption, bundleOptionSelectionResult } =
        action.payload;
      const targetedBundleGroup = state.prizes[prizeSlug].bundleGroups?.find(
        ({ id }) => id === bundleGroupId,
      );

      if (!targetedBundleGroup) {
        return;
      }

      const targetedBundleOption = targetedBundleGroup.bundleOptions.find(
        ({ title }) => title === bundleOption.title,
      );

      if (!targetedBundleOption) {
        return;
      }

      targetedBundleOption.suboptions = bundleOptionSelectionResult.map(({ item, count }) => ({
        ...item,
        selectedQuantity: count,
      }));

      const subtitleParts =
        targetedBundleGroup.bundleOptions.length === 1 ? [] : [bundleOption.title];
      bundleOptionSelectionResult
        .filter(({ count }) => count > 0)
        .forEach(({ item }) => {
          subtitleParts.push(item.title);
        });
      targetedBundleGroup.subtitle = subtitleParts.join(' · ');
      targetedBundleGroup.completed = bundleOptionSelectionResult.some(({ count }) => count > 0);
    },
    setNeedsToReCompleteUserSelection: (
      state,
      action: {
        payload: { prizeSlug: string; needsToReCompleteUserSelection: boolean };
      },
    ) => {
      const { prizeSlug, needsToReCompleteUserSelection } = action.payload;
      state.prizes[prizeSlug].needsToReCompleteUserSelection = needsToReCompleteUserSelection;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
  },
});

export const {
  setBrands,
  setPromotedPrizes,
  setLimitedPrizes,
  setLuckyMomentPrizes,
  setLuckyMomentPrizesComingSoon,
  setGuaranteedPrizes,
  setGuaranteedPrizesComingSoon,
  increaseChances,
  setPrize,
  markBundleOptionAsSelected,
  updateListItemsSelectorsSelectedItems,
  updateBundleSuboptionsSelectedQuantities,
  setNeedsToReCompleteUserSelection,
} = prizesSlice.actions;
export default prizesSlice.reducer;
