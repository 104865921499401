import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { GetPromotedItemsRequest, GetPromotedItemsResponse } from '../models/Promoted.model';

export const promotedAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getPromotedItems: builder.mutation<GetPromotedItemsResponse, GetPromotedItemsRequest>({
      query: ({ category }) => ({
        url: Endpoints.getPromotedItems.replace('{{category}}', category),
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetPromotedItemsMutation } = promotedAPI;
