import { View, SafeAreaView, useWindowDimensions } from 'react-native';

import { sharedStyles } from '../../../config/theme/shared.styles';
import BATIcon from '../BATIcon';
import BATText from '../common/BATText';
import colors from '../../../config/theme/shared/colors';

interface BATEarnedPointsToastProps {
  text1: string;
  text2: string;
  onHide: () => void;
}

export const BATEarnedPointsToast = ({ text1, text2, onHide }: BATEarnedPointsToastProps) => {
  const { width } = useWindowDimensions();
  return (
    <SafeAreaView style={[sharedStyles.heightViewportFull, sharedStyles.relative]}>
      <View style={{ top: 10, width: '100%', alignItems: 'center', position: 'absolute' }}>
        <View
          style={[
            {
              maxWidth: 0.9 * width,
              width: 480,
              borderLeftWidth: 4,
              borderLeftColor: colors.successIcon,
              backgroundColor: colors.card_background_lighter,
            },

            sharedStyles.borderRadiusHalf,
            sharedStyles.padding,
          ]}>
          <View style={{ flexDirection: 'row', gap: 8 }}>
            <BATIcon
              name="check-mark"
              size={24}
              color={colors.successIcon}
              style={{ padding: 4, gap: 8 }}
            />
            <View style={{ flex: 1, alignItems: 'flex-start' }}>
              <BATText color={colors.grey_title}>{text1}</BATText>
              <BATText type="body-small">{text2}</BATText>
            </View>
            <BATIcon
              name="close"
              size={16}
              color={colors.grey_text}
              onPress={onHide}
              style={{ padding: 4, gap: 8, zIndex: 1 }}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};
