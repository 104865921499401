import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import api from '../config/api';
import authReducer from '../screens/Authentication/slices/authSlice';
import appSettingsReducer from './slices/appSettingsSlice';
import notificationReducer from '../screens/Feed/slices/notificationSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storiesSlice from '../screens/Stories/slices/storiesSlice';
import contentSlice from '../screens/Feed/slices/contentSlice';
import surveySlice from '../screens/Survey/slices';
import campaignSlice from '../screens/Campaigns/slice/campaignSlice';
import userPointsSlice from '../screens/Profile/slice/userPointsSlice';
import favoritesSlice from '../screens/Favorites/slices/favoritesSlice';
import prizesSlice from '../screens/Prizes/slices/prizesSlice';
import walletSlice from '../screens/Wallet/slice/walletSlice';
import collectionsSlice from '../screens/Collections/slices/collectionsSlice';
import articlesSlice from '../screens/Articles/slices/articlesSlice';
import tasteProfileSlice from '../screens/TasteProfile/slices/tasteProfileSlice';
import devicesSlice from '../screens/Profile/Devices/slices/devicesSlice';
import codesSlice from '../src/slices/codesSlice';
import confettiSlice from '../src/slices/confettiSlice';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  stories: storiesSlice,
  campaign: campaignSlice,
  notification: notificationReducer,
  content: contentSlice,
  appSettings: appSettingsReducer,
  surveys: surveySlice,
  userPoints: userPointsSlice,
  favorites: favoritesSlice,
  prizes: prizesSlice,
  wallet: walletSlice,
  collections: collectionsSlice,
  articles: articlesSlice,
  tasteProfile: tasteProfileSlice,
  devices: devicesSlice,
  codes: codesSlice,
  confetti: confettiSlice,
});

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: [
    'api',
    'content',
    'stories',
    'prizes',
    'collections',
    'articles',
    'favorites',
    'wallet',
    'tasteProfile',
    'confetti',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
