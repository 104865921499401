import Toast, { BaseToast } from 'react-native-toast-message';
import React from 'react';
import { View } from 'react-native';
import colors from './shared/colors';

import BATIcon from '../../src/components/BATIcon';
import BATAnnounceToast from '../../src/components/common/BATAnnounceToast';
import { BATEarnedPointsToast } from '../../src/components/announcements/BATEarnedPointsToast';

export const toastConfig = {
  error: ({ text1, text2, props, ...rest }: any) => (
    <BaseToast
      {...props}
      {...rest}
      renderLeadingIcon={() => (
        <View style={{ justifyContent: 'center', marginLeft: 19 }}>
          <BATIcon name={'info-icon'} size={20} color={'red'} />
        </View>
      )}
      renderTrailingIcon={() => (
        <View style={{ justifyContent: 'center', marginRight: 19 }}>
          <BATIcon name={'close'} size={12} color={'white'} onPress={() => Toast.hide()} />
        </View>
      )}
      style={{
        borderLeftColor: colors.toastError,
        backgroundColor: colors.cardsBackground,
        overflow: 'hidden',
        width: '90%',
      }}
      text1Style={{
        fontSize: 16,
        color: colors.text,
        fontWeight: '400',
      }}
      text2Style={{
        fontSize: 12,
        color: colors.text,
        fontWeight: '300',
      }}
      text1={text1}
      text2={text2}
      text2NumberOfLines={4}
      onTrailingIconPress={() => Toast.hide()}
    />
  ),
  announce: ({ text1, text2, onPress, props }: any) => (
    <BATAnnounceToast
      text1={text1}
      text2={text2}
      onPress={onPress}
      onHide={() => {
        props.onClose();
      }}
    />
  ),
  earnedPoints: ({ text1, text2 }: any) => (
    <BATEarnedPointsToast text1={text1} text2={text2} onHide={() => Toast.hide()} />
  ),
};
